import { PostItemTypes } from '@/utils/enums';
import { i18n } from 'next-i18next';

export const SHOP_LINK = 'https://www.brokenships.store/';

export const GET_YOUR_GUIDE_LINK =
	'http://www.getyourguide.de/-t390064/?partner_id=X3V3K3Q&utm_force=0&partner_ticketing_activity_ids=390064';

export const SOCIAL_LINKS = [
	{
		id: 'facebook',
		name: 'Facebook',
		link: 'https://www.facebook.com/Brokenships/',
	},
	{
		id: 'instagram',
		name: 'Instagram',
		link: 'https://www.instagram.com/brokenships/',
	},
	{
		id: 'tikTok',
		name: 'TikTok',
		link: 'https://www.tiktok.com/@brokenships/',
	},
	{
		id: 'bluesky',
		name: 'Bluesky',
		link: 'https://bsky.app/profile/brokenships.bsky.social/',
	},
	{
		id: 'pinterest',
		name: 'Pinterest',
		link: 'https://www.pinterest.com/brokenships/',
	},
];

export const ModalTypes = {
	REGISTER: 'register',
	LOGIN: 'login',
	SHARE_STORY: 'share-story',
	CONTRIBUTE: 'contribute',
	DELETE_STORY: 'delete-story',
	DONATE: 'donate',
	DONATE_CONFIRM: 'donate-confirm',
	CHANGE_PASSWORD: 'change-password',
	HOST_AN_EXIBITION: 'host-an-exibition',
	BREAKUPS_MAP: 'breakups-map',
	EDIT_STORY: 'edit-story',
	FAQ: 'faq',
	PRESS_KIT: 'press-kit',
	IN_PRESS: 'in-press',
	ABOUT_MUSEUM: 'about-museum',
	CONTACT: 'contact',
	FORGOT_PASSWORD: 'forgot-password',
	FORGOT_PASSWORD_CONFIRMATION: 'forgot-password-confirmation',
};

export const POST_ITEMS_TYPES = {
	'Collection::Item': PostItemTypes.COLLECTION,
	'User::Story': PostItemTypes.STORY,
	'News::Post': PostItemTypes.NEWS,
};

export const translatePostItemType = (type: PostItemTypes) => {
	const t = i18n.t;

	switch (type) {
		//Delete changeLog
		case PostItemTypes.COLLECTION:
			return t('collection');
		case PostItemTypes.STORY:
			return t('story');
		case PostItemTypes.NEWS:
			return t('news');
	}
};

export const POST_ORIENTATION = {
	VERTICAL: 'vertical',
	HORIZONTAL: 'horizontal',
};

export const APP = {
	title: 'Brokenships',
	description: 'Where memories of broken hearts live.',
	head: {
		titleTemplate: 'Brokenships: %s',
		meta: [
			{ name: 'description', content: 'Where memories of broken hearts live.' },
			{ name: 'keywords', content: 'museum, broken, relationships' },
			{ charset: 'utf-8' },
			{ property: 'og:type', content: 'website' },
			{ property: 'og:title', content: 'Brokenships' },
			{ property: 'og:description', content: 'Where memories of broken hearts live.' },
			{ property: 'og:site_name', content: 'Brokenships' },
			{ property: 'og:image', content: '/images/facebook-share-default.png' },
			{ property: 'twitter:card', content: 'summary_large_image' },
			{ property: 'twitter:site', content: '@Brokenships' },
			{ property: 'twitter:creator', content: '@Brokenships' },
			{ property: 'twitter:title', content: 'Brokenships' },
			{ property: 'twitter:description', content: 'Where memories of broken hearts live.' },
			{ property: 'twitter:image', content: '/images/twitter-share-default.png' },
		],
	},
};

export const FEED_ACTION_TYPES = {
	CONTRIBUTE: 'CONTRIBUTE',
	SHARE_STORY: 'SHARE_STORY',
};

export const MY_PROFILE_FAVORITES_TYPES = {
	'Collection::Item': 'collection/item',
	'User::Story': 'story',
	'News::Post': 'news/post',
};
